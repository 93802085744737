import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {combineReducers} from "redux";
import {Provider} from "react-redux";
import {configureStore} from "@reduxjs/toolkit";
import './index.css';
import App from './App';

import userSlice from './store/slices/user';
import folderSlice from './store/slices/folders';
import presentationSlice from './store/slices/presentations';
import slideSlice from './store/slices/slides';

const rootReducer = combineReducers({
    user: userSlice,
    folders: folderSlice,
    presentations: presentationSlice,
    slides: slideSlice
});

const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production'
})

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
