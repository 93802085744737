import React from 'react';
import classes from './App.module.scss';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {routes} from "./utils/consants/routes";
import ProtectedRoute from "./hoc/protectedRoute/protectedRoute";
import PresentationList from "./containers/presentation/presentationList";

function App() {
    return (
        <div>
            <header className={classes.header}>
                <p>
                    Veeva Edetailer Builder
                </p>
            </header>
            <Router>
                <Switch>
                    <Route exact path={routes.login}>
                        Login
                    </Route>
                    <Route exact path={routes.registration}>
                        Registration
                    </Route>
                    <Route exact path={routes.forgotPassword}>
                        ForgotPassword
                    </Route>
                    <ProtectedRoute exact path={routes.folder} render={(props: any) => {
                        return <div>Protected Folder</div>
                    }}/>
                    <Route exact path={routes.home}>
                        Home (todo: redirect to first folder)
                    </Route>
                    <Route path="/">
                        Landing
                        <PresentationList/>
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
