import React from 'react';
import {Redirect, Route} from "react-router";
import {routes} from "../../utils/consants/routes";

type ProtectedRouteProps = {
    component?: React.Component | React.FunctionComponent | React.ReactNode;
    path: string;
    exact?: boolean;
    render?: any;
    roles?: string[];
};

const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = (props) => {
    const {component: Component, render, path, ...rest} = props;
    //const isAuthenticated = useSelector((state: any) => state.auth.token || null);
    const isAuthenticated = Math.random() < 0.5;

    let renderFunction: any = (props: any) => {
        if (!isAuthenticated) {
            return <Redirect to={routes.login}/>;
        }

        // @ts-ignore
        return <Component {...props} />;
    };

    if (render && isAuthenticated) {
        renderFunction = render;
    }

    return (
        <Route
            path={path}
            {...rest}
            render={renderFunction}
        />
    );
};

export default ProtectedRoute;
