import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {StoreInterface} from "../../utils/interfaces/store";
import {endpoints} from '../../utils/consants/endpoints';
import axios from "axios";
import {setAllPresentations, setSearchPresentation} from "../../store/slices/presentations";
import {Button, ButtonGroup, Grid, TextField} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import image from "./contemplative-reptile.jpg";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CardActions from "@material-ui/core/CardActions";
import Container from "@material-ui/core/Container";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import {makeStyles} from "@material-ui/core/styles";
import {AddNewPresentationForm, DeletePresentationForm, EditPresentationNameForm} from "./presentationEdit";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    input: {
        display: 'none',
    },
    buttonGroup: {
        justifyContent: 'flex-end'
    }

});


const PresentationList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const presentations = useSelector((store: StoreInterface) => store.presentations.presentations);

    const [openNewPresentationDialog, setOpenNewPresentationDialog] = useState(false);
    const [openEditPresentationNameDialog, setOpenEditPresentationNameDialog] = useState(false);
    const [editPresentationNameObject, setEditPresentationNameObject] = useState<any>([]);

    const [openDeletePresentationNameDialog, setOpenDeletePresentationNameDialog] = useState(false);
    const [deletablePresentation, setDeletablePresentation] = useState<any>([]);

    const addNewPresentation = () => {
        setOpenNewPresentationDialog(true);
    }

    const editPresentationName = (presentationID: number) => {
        console.log("edit presentation name by id :", presentationID)
        const editablePresentation = presentations[presentationID];
        setEditPresentationNameObject(editablePresentation);
        setOpenEditPresentationNameDialog(true);
        console.log("editPresentationName: ", editablePresentation);
    }

    const editPresentation = (presentationID: number) => {
        console.log("edit presentation by id :", presentationID)
    }

    const deletePresentation = (presentationID: number) => {
        console.log("delete presentation by id :", presentationID)
        const deletablePresentation = presentations[presentationID];
        setDeletablePresentation(deletablePresentation);
        setOpenDeletePresentationNameDialog(true);
        console.log("deletablePresentation: ", deletablePresentation);
    }

    useEffect(() => {

        const getAllPresentation = () => {
            axios.get(`${process.env.REACT_APP_API_BASE_URL + endpoints.presentation}`)
                .then((response) => {
                    console.log("getAllPresentation:", response.data);
                    dispatch(setAllPresentations(response.data));
                })
                .catch((error) => console.warn(error))
        }

        getAllPresentation();

    }, [dispatch])

    const handleClose = () => {
        setOpenNewPresentationDialog(false);
    }

    const handleEditPresentationNameClose = () => {
        setOpenEditPresentationNameDialog(false);
    }

    const PresentationCards = Object.keys(presentations).length > 0 ? Object.keys(presentations).map((key: string) => {
        const presentation = presentations[key];
        const uniqInputID = `edit-presentation-name-${presentation.id}`;
        const isShowPresentation = presentation.show;

        if (isShowPresentation) {
            return <Grid item key={presentation.id}>
                <Card className={classes.root}>
                    <CardMedia
                        className={classes.media}
                        image={image}
                        title="Contemplative Reptile"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {presentation.name}
                            <input accept="text/strings" className={classes.input} id={uniqInputID} type="text" key={presentation.id}/>
                            <label htmlFor={uniqInputID}>
                                <IconButton color="primary" aria-label="edit presentation-name" component="span"
                                            onClick={() => editPresentationName(presentation.id)}>
                                    <EditIcon/>
                                </IconButton>
                            </label>
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Subtitle from presentation
                        </Typography>
                    </CardContent>
                    <CardActions className={classes.buttonGroup}>
                        <ButtonGroup variant="contained" size="small">
                            <Button aria-label="delete" color="primary" onClick={() => editPresentation(presentation.id)}>
                                <EditIcon/>
                            </Button>
                            <Button aria-label="delete" color="secondary" onClick={() => deletePresentation(presentation.id)}>
                                <DeleteIcon/>
                            </Button>
                        </ButtonGroup>
                    </CardActions>
                </Card>
            </Grid>;
        }
        return null;
    }) : <h3>You dont have any presentation</h3>;

    return (
        <Container>

            <TextField
                id="standard-full-width"
                label="Search presentation"
                fullWidth
                style={{margin: 8}}
                placeholder="Search presentation by name"
                margin="normal"
                onChange={event => {
                    dispatch(setSearchPresentation(event.target.value))
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <label htmlFor="icon-button-file">
                <Button color="primary" aria-label="add presentation" component="span" onClick={addNewPresentation}>
                    <AddIcon/> Add Presentation
                </Button>
            </label>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}>

                {PresentationCards}
            </Grid>

            <Dialog open={openNewPresentationDialog} onClose={handleClose}>
                <AddNewPresentationForm toggleStatus={setOpenNewPresentationDialog}/>
            </Dialog>

            <Dialog open={openEditPresentationNameDialog} onClose={handleEditPresentationNameClose}>
                <EditPresentationNameForm toggleStatus={setOpenEditPresentationNameDialog} editablePresentation={editPresentationNameObject}/>
            </Dialog>

            <Dialog open={openDeletePresentationNameDialog} onClose={handleEditPresentationNameClose}>
                <DeletePresentationForm toggleStatus={setOpenDeletePresentationNameDialog} deletablePresentation={deletablePresentation}/>
            </Dialog>
        </Container>

    );
};

export default PresentationList;
