import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {PresentationActionInterface, PresentationInterface, PresentationSliceInterface} from "../../utils/interfaces";

const initialState = {
    activePresentationID: '',
    presentations: {}
} as PresentationSliceInterface;

const presentationSlice = createSlice({
    name: 'presentation',
    initialState,
    reducers: {
        setPresentationName(state, action: PayloadAction<PresentationActionInterface>) {
            state.presentations[action.payload.id].name = action.payload.value;
        },
        setAllPresentations(state, action: PayloadAction<PresentationInterface[]>) {
            action.payload.forEach((presentation: PresentationInterface) => {
                state.presentations[presentation.id] = presentation;
                state.presentations[presentation.id].show = true;
            })
        },
        createNewPresentation(state, action: PayloadAction<PresentationInterface>) {
            state.presentations[action.payload.id] = action.payload;
        },
        setSearchPresentation(state, action: PayloadAction<string>) {
            if (action.payload.length >= 3) {
                Object.keys(state.presentations).forEach((key) => {
                    if (!state.presentations[key].name.startsWith(action.payload)) {
                        state.presentations[key].show = false;
                    }
                })
            } else {
                Object.keys(state.presentations).forEach((key) => {
                    state.presentations[key].show = true;
                })
            }
        },
        deletePresentation(state, action: PayloadAction<PresentationActionInterface>) {
            delete state.presentations[action.payload.id];
        },
    },
});

export const {setPresentationName, createNewPresentation, setAllPresentations, setSearchPresentation, deletePresentation} = presentationSlice.actions;
export default presentationSlice.reducer;
