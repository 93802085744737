import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    SlideActionInterface,
    SlideSliceInterface
} from "../../utils/interfaces";

const initialState = {
    activeSlideID: '',
    slides: {}
} as SlideSliceInterface;

const slideSlice = createSlice({
    name: 'slide',
    initialState,
    reducers: {
        setSlideWeight(state, action: PayloadAction<SlideActionInterface>) {
            if (state.slides) {
                state.slides[action.payload.id].weight = action.payload.value;
            }
        },
    },
});

export const {setSlideWeight} = slideSlice.actions;
export default slideSlice.reducer;
