import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserInterface} from "../../utils/interfaces";

const initialState = {id: 0, email: '', name: '', token: null} as UserInterface

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        assignToken(state, action: PayloadAction<string>) {
            state.token = action.payload
        },
        destroyToken(state) {
            state.token = null;
        },
        setUsername(state, action: PayloadAction<string>) {
            state.name = action.payload
        },
        setUserEmail(state, action: PayloadAction<string>) {
            state.email = action.payload
        },
        setUser(state, action: PayloadAction<any>) {
            state = action.payload;
        },
    },
});

export const {assignToken, destroyToken, setUsername, setUserEmail, setUser} = userSlice.actions;
export default userSlice.reducer;
