import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FolderActionInterface, FolderInterface, FolderSliceInterface} from "../../utils/interfaces";

const initialState = {
    activeFolderID: '',
    folders: {}
} as FolderSliceInterface;

const folderSlice = createSlice({
    name: 'folder',
    initialState,
    reducers: {
        setFolderName(state, action: PayloadAction<FolderActionInterface>) {
            state.folders[action.payload.id].name = action.payload.value
        },
        setFolderParentID(state, action: PayloadAction<FolderActionInterface>) {
            state.folders[action.payload.id].parent_id = action.payload.value
        },
        setFolderChildIDs(state, action: PayloadAction<FolderActionInterface>) {
            state.folders[action.payload.id].child_ids = action.payload.value
        },
        setFolder(state, action: PayloadAction<FolderInterface>) {
            state.folders[action.payload.id] = action.payload;
        },
    },
});

export const {setFolderName, setFolderParentID, setFolderChildIDs, setFolder} = folderSlice.actions;
export default folderSlice.reducer;
