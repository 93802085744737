import React from 'react';
import * as yup from "yup";
import {useDispatch} from "react-redux";
import {v4 as uuidv4} from "uuid";
import {useFormik} from "formik";
import {endpoints} from '../../utils/consants/endpoints';
import getCurrentDate from "../../utils/functions/getCurrentDate";
import {createNewPresentation, deletePresentation, setPresentationName} from "../../store/slices/presentations";
import axios from "axios";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {Button} from "@material-ui/core";

const validationNewPresentationSchema = yup.object({
    name: yup.string(),
    folder_id: yup.string(),
    default_styles: yup.string(),
    created_by: yup.number(),
    created_at: yup.date().notRequired().min(new Date(1900, 80, 1)),
});
const AddNewPresentationForm = (props: { toggleStatus: (status: boolean) => void }) => {
    const dispatch = useDispatch();
    const newPresentationUUID = uuidv4();
    const formik = useFormik({
        initialValues: {
            id: newPresentationUUID,
            name: '',
            folder_id: 'presentation_1',
            default_styles: 'up coming',
            created_by: 1,
            created_at: getCurrentDate
        },
        validationSchema: validationNewPresentationSchema,
        onSubmit: (presentationValues) => {

            const validNewPresentation = {
                id: presentationValues.id,
                name: presentationValues.name,
                folder_id: presentationValues.folder_id,
                asset_ids: [''],
                font_ids: [''],
                created_at: presentationValues.created_at,
                last_modified_at: null,
                last_modified_by: null,
                show: true,
            };

            console.log("newPresentationValues: ", presentationValues);
            dispatch(createNewPresentation(validNewPresentation));
            handleClose();

            axios.post(`${process.env.REACT_APP_API_BASE_URL + endpoints.presentation}`, presentationValues)
                .then((response) => {
                    console.log("AddNewPresentationFormResponse", response);
                })
                .catch((error) => console.warn(error))
        },
    });

    const handleClose = () => {
        props.toggleStatus(false);
    }

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>Új prezentáció létrehozása</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Nem találod a prezentációt? Hozzd létre!
                    </DialogContentText>
                    <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label="Presentation Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <TextField
                        fullWidth
                        id="folder_id"
                        name="folder_id"
                        label="Folder id"
                        type="text"
                        value={formik.values.folder_id}
                        onChange={formik.handleChange}
                        error={formik.touched.folder_id && Boolean(formik.errors.folder_id)}
                        helperText={formik.touched.folder_id && formik.errors.folder_id}
                    />
                </DialogContent>
                <DialogActions>
                    <ButtonGroup variant="contained" size="small">
                        <Button onClick={handleClose} color="primary">Cancel</Button>
                        <Button type="submit" color="secondary">Create</Button>
                    </ButtonGroup>
                </DialogActions>
            </form>
        </div>
    );
};

const validationEditPresentationSchema = yup.object({
    name: yup.string(),
    folder_id: yup.string(),
    default_styles: yup.string(),
    updated_at: yup.date().notRequired().min(new Date(1900, 80, 1)),
});
const EditPresentationNameForm = (props: { toggleStatus: (status: boolean) => void, editablePresentation: any }) => {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            id: props.editablePresentation.id,
            name: props.editablePresentation.name,
            folder_id: 'presentation_1',
            default_styles: 'up coming',
            updated_at: getCurrentDate,
        },
        validationSchema: validationEditPresentationSchema,
        onSubmit: (values) => {
            dispatch(setPresentationName({id: values.id, value: values.name}));
            axios.put(`${process.env.REACT_APP_API_BASE_URL + endpoints.presentation + "/" + props.editablePresentation.id}`, values)
                .then((response) => {
                    console.log(response);
                    handleClose();
                })
                .catch((error) => console.warn(error));
        }
    });

    const handleClose = () => {
        props.toggleStatus(false);
    }

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>Prezentáció átnevezése</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label="Presentation Rename"
                        type="text"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                </DialogContent>
                <DialogActions>
                    <ButtonGroup variant="contained" size="small" disableElevation={true}>
                        <Button onClick={handleClose} color="primary">Cancel</Button>
                        <Button type="submit" color="secondary">Rename</Button>
                    </ButtonGroup>
                </DialogActions>
            </form>
        </div>
    );
};

const validationDeletePresentationSchema = yup.object({
    id: yup.string()
});

const DeletePresentationForm = (props: { toggleStatus: (status: boolean) => void, deletablePresentation: any }) => {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            id: props.deletablePresentation.id
        },
        validationSchema: validationDeletePresentationSchema,
        onSubmit: (values) => {
            console.log("values: ", values.id);
            dispatch(deletePresentation({id: values.id, value: ''}));
            axios.delete(`${process.env.REACT_APP_API_BASE_URL + endpoints.presentation + "/" + values.id}`)
                .then((response) => {
                    console.log(response);
                    handleClose();
                })
                .catch((error) => console.warn(error))
        }
    });

    const handleClose = () => {
        props.toggleStatus(false);
    }

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>Prezentáció törlése</DialogTitle>
                <DialogContentText>
                    Biztos hogy törölni szeretnéd a prezentációt?
                </DialogContentText>
                <DialogActions>
                    <ButtonGroup variant="contained" size="small">
                        <Button onClick={handleClose} color="primary">Cancel</Button>
                        <Button type="submit" color="secondary">Delete</Button>
                    </ButtonGroup>
                </DialogActions>
            </form>
        </div>
    );
};

export {AddNewPresentationForm, EditPresentationNameForm, DeletePresentationForm};
